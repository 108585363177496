<template>
  <div class="ma-4">
    <h1 class="text-h2 mb-8">{{ title }}</h1>
    <div v-if="stage == 4">
      <v-row>
        <v-col cols="12" sm="6" class="pa-0">
          <Movie :url="mov_url" :giveTime="currentRatioForVideo" />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <Transcript :url="transcript_url" :giveTime="current_time" />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <Apisnote
            :url="apisnote_url"
            :giveTime="current_time"
            :startTime="diagram_start_time"
            :endTime="diagram_end_time"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <Diagram :url="diagram_url" @update="updated" />
        </v-col>
      </v-row>
    </div>
    <!--解析をステージで分けている。ステージ0はファイルのアップロードの前。 今は開発用に常に表示にする-->
    <div v-if="stage >= 0">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="mb-8">not ready, please upload files below.</div>
            <h2>Raw file upload [for test use]</h2>
            <!--Uploaderをインポートしているので使用可能-->
            <Uploader :id="id" />
            <div class="my-10" />
            <h2>Analyze Process</h2>
            <div>(to be implemented)</div>
            <div>stage: {{ stage }}</div>
            <v-stepper alt-labels v-model="stage">
              <v-stepper-header>
                <template v-for="(step, i) in steps">
                  <v-stepper-step
                    :complete="stage > i - 1"
                    :step="i - 1"
                    :key="i"
                  >
                    <font size="2">
                      {{ step }}
                    </font>
                  </v-stepper-step>
                  <v-divider
                    :key="i + 0.5"
                    v-if="!(i == steps.length - 1)"
                  ></v-divider>
                </template>
              </v-stepper-header>
            </v-stepper>
            <div class="my-10" />
            <FaceCalibration
              v-if="stage == 2"
              :n_face="mtg_info.n_face"
              :mtg_id="id"
              :url="calibration_url"
              @update="updateFaceCalibration"
            />
            <div class="my-5" />
            <v-btn @click="batch()">Start Batch</v-btn>
          </v-col>
        </v-row>
        <!--バッチを始めるためのボタン-->
        <div>{{ batch_msg }}</div>
      </v-container>
    </div>
    <div style="height: 50vh"></div>
  </div>
</template>

<script>
import { API, Storage } from "aws-amplify";

import Uploader from "../components/Uploader"; //uploaderをインポートして使えるようにする
import Movie from "../components/mtg/Movie.vue";
import Diagram from "../components/mtg/Diagram.vue";
import Transcript from "../components/mtg/Transcript.vue";
import Apisnote from "../components/mtg/Apisnote.vue";
import FaceCalibration from "../components/FaceCalibration.vue";

export default {
  //これでAppという別名でエクスポートしている。
  name: "App",
  components: {
    //読みこむ子コンポーネント
    Movie,
    Diagram,
    Transcript,
    Apisnote,
    Uploader,
    FaceCalibration,
  },

  data: () => ({
    id: "",
    mtg_info: {},
    mov_url: "",
    diagram_url: "",
    transcript_url: "",
    apisnote_url: "",
    calibration_url: "",
    //Diagramから読み取った比率
    currentRatio: 0,
    //Videoに渡すための全体の動画時間に対する再生場所の比率
    currentRatioForVideo: 0,
    // batch
    batch_msg: "",
    //将来的にはaws経由でビデオ開始時刻と終了時刻、それからダイアグラムの開始時刻と終了時刻を取得
    video_start_time: new Date(),
    video_end_time: new Date(),
    diagram_start_time: new Date(),
    diagram_end_time: new Date(),
    current_time: new Date(),
    steps: [
      "ファイルアップロード",
      "smile recognition",
      "face clustering",
      "顔の対応を入力",
      "smile calibration",
      "Sync",
      "AmazonTranscribe",
      "Transcript",
      "Visualize",
    ],
  }),

  computed: {
    title: function() {
      if (this.mtg_info.workshop || this.mtg_info.team) {
        return this.mtg_info.workshop + " " + this.mtg_info.team;
      }
      return "";
    },
    stage: function() {
      if (this.mtg_info.stage || this.mtg_info.stage === 0) {
        return this.mtg_info.stage;
      }
      return 4;
    },
  },

  methods: {
    //二つの時刻の間の比率に該当する時刻を返す
    RatioToDate: function(Date1, Date2, ratio) {
      var Date1MiliSecond = Date1.getTime();
      var Date2MiliSecond = Date2.getTime();
      var MiliSecond = Date2MiliSecond - Date1MiliSecond;
      MiliSecond = MiliSecond * ratio;
      MiliSecond = MiliSecond + Date1MiliSecond;
      var DateNow = new Date(MiliSecond);
      console.log(DateNow);
      return DateNow;
    },
    //三つの時刻が与えられた時に間にある時刻がどの割合にいるのかを返す
    DateToRatio: function(Date1, Date2, Date3) {
      var Date1MiliSecond = Date1.getTime();
      var Date2MiliSecond = Date2.getTime();
      var Date3MiliSecond = Date3.getTime();
      var TotalMiliSecond = Date2MiliSecond - Date1MiliSecond;
      var MiliSecond = Date3MiliSecond - Date1MiliSecond;
      var ratio = MiliSecond / TotalMiliSecond;
      console.log(MiliSecond, TotalMiliSecond);
      return ratio;
    },
    //jsで時刻の比較や演算をするためにはDateオブジェクトにする。
    //まずはこのDateオブジェクトに変換して考えることにする。
    awsTimeToDateVideo: function() {
      //今は仮に適当な値を返す
      this.video_start_time = new Date(2021, 3, 2, 18, 38, 0);
      this.video_end_time = new Date(2021, 3, 2, 21, 0, 54);
    },
    awsTimeToDateDiagram: function() {
      //今は仮に適当な値を返す
      this.diagram_start_time = new Date(2021, 3, 2, 18, 39, 0);
      this.diagram_end_time = new Date(2021, 3, 2, 20, 51, 0);
    },
    //子供から比率を受け取ったときに今の時刻を更新
    updated: function(ratio) {
      //console.log(ratio);
      //this.currentRatio = ratio;
      this.currentRatio = ratio;
      //awsから受け取った日時を読み込む。videoの方もdiagramの方も。
      this.awsTimeToDateDiagram();
      this.awsTimeToDateVideo();
      //diagramから受け取ったratioで示された時刻を読み込む
      var CurDate = this.RatioToDate(
        this.diagram_start_time,
        this.diagram_end_time,
        this.currentRatio
      );
      this.current_time = CurDate;
      //videoに渡すための比率も計算して代入。
      var CurVideoRatio = this.DateToRatio(
        this.video_start_time,
        this.video_end_time,
        this.current_time
      );
      this.currentRatioForVideo = CurVideoRatio;
    },
    getUrl: async function(key, handler) {
      const dataExpireSeconds = 60 * 60; // 1時間有効なURLを取得
      Storage.get("mtgs/" + this.id + "/" + key, {
        level: "private",
        expires: dataExpireSeconds,
      })
        .then((result) => {
          console.log(result);
          handler(result);
          console.log(this);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadMtgInfo: function(callback) {
      const dataExpireSeconds = 60;
      Storage.get("mtgs/" + this.id + "/mtg_info.json", {
        level: "private",
        expires: dataExpireSeconds,
      })
        .then((url) => {
          this.axios.get(url).then((result) => {
            console.log(result);
            this.mtg_info = result.data;
            callback();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    batch: async function() {
      const myInit = {
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        queryStringParameters: {
          mtgId: this.id,
          ver: "full",
        },
      };
      const response = await API.get("batch", "/batch", myInit);
      this.batch_msg = response.message;
    },
    updateFaceCalibration: function(content) {
      const filename = "calibration.csv";
      Storage.put("mtgs/" + this.id + "/" + filename, content, {
        level: "private",
        contentType: "text/json",
      })
        .then((response) => {
          // response 処理
          console.log(response);
        })
        .catch((error) => {
          // error 処理
          console.error(error);
        });
    },
  },

  mounted: function() {
    this.id = this.$route.params["id"];
    // TODO: exist check
    if (this.id == "") {
      this.$router.push("/");
    }

    this.loadMtgInfo(() => {
      if (this.stage == 2) {
        this.getUrl("calibration.csv", (result) => {
          this.calibration_url = result;
        });
      } else if (this.stage == 4) {
        this.getUrl("mov.mp4", (result) => {
          this.mov_url = result;
        });
        this.getUrl("diagram.png", (result) => {
          this.diagram_url = result;
        });
        this.getUrl("transcript.csv", (result) => {
          this.transcript_url = result;
        });
        this.getUrl("apisnote.json", (result) => {
          this.apisnote_url = result;
        });
      }
    });
  },
};
</script>
